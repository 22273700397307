import { HStack, VStack, Stack } from '@chakra-ui/layout';
import {} from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { ScFacebook } from '@styled-icons/evil/ScFacebook';
import { ScInstagram } from '@styled-icons/evil/ScInstagram';
import { ScLinkedin } from '@styled-icons/evil/ScLinkedin';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export function AuthorCard({
  author: {
    id,
    bio,
    firstName,
    lastName,
    profilePicture,
    facebook,
    instagram,
    linkedin,
  },
  ...rest
}) {
  const fullName = `${firstName} ${lastName}`;
  return (
    <Box as={Link} to={`/autores/${id}`} boxShadow="base" p="16px" {...rest}>
      <Stack
        spacing={8}
        direction={{ base: 'column', md: 'row' }}
        align="center"
      >
        <Box borderRadius="50%" overflow="hidden" h="125px" w="125px">
          <GatsbyImage image={getImage(profilePicture)} />
        </Box>
        <VStack align="flex-start" flex="1">
          <Link to={`/autores/${id}`} style={{ gridArea: 'name' }}>
            <Heading textAlign={{ base: 'center', md: 'left' }}>
              {fullName}
            </Heading>
          </Link>
          <HStack justify={{ base: 'center', md: 'flex-start' }} w="100%">
            {instagram && (
              <a href={`https://instagram.com/${instagram}/`}>
                <ScInstagram height="32" width="32" />
              </a>
            )}
            {facebook && (
              <a href={`https://www.facebook.com/${facebook}`}>
                <ScFacebook height="32" width="32" />
              </a>
            )}
            {linkedin && (
              <a href={`https://www.linkedin.com/in/${linkedin}/`}>
                <ScLinkedin height="32" width="32" />
              </a>
            )}
          </HStack>
          <Link to={`/autores/${id}`} style={{ gridArea: 'bio' }}>
            {bio.childMarkdownRemark.excerpt}
          </Link>
        </VStack>
      </Stack>
    </Box>
  );
}
