import React from 'react';
import { SimpleGrid } from '@chakra-ui/layout';

export const AuthorList = (props) => (
  <SimpleGrid
    as="ul"
    autoRows="auto"
    rowGap="24px"
    listStyleType="none"
    {...props}
  />
);
