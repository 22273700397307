import React from 'react';
import { graphql } from 'gatsby';

import { HeadingXL, Layout, SEO, HeaderLogo } from '../components';
import { AuthorCard } from '../components/AuthorCard';
import { AuthorList } from '../components/AuthorList';
import { Container } from '@chakra-ui/layout';

const About = ({ data }) => {
  const authors = data.allAuthorsYaml.nodes;
  return (
    <>
      <SEO title="Invitades" />
      <HeaderLogo />
      <Layout>
        <Container maxW="900px">
          <HeadingXL>Invitades</HeadingXL>
          <AuthorList>
            {authors.map((author) => (
              <AuthorCard key={author.id} author={author} />
            ))}
          </AuthorList>
        </Container>
      </Layout>
    </>
  );
};

export default About;

export const query = graphql`
  {
    allAuthorsYaml(filter: { isGuest: { eq: true } }) {
      nodes {
        id
        firstName
        lastName
        facebook
        linkedin
        instagram
        bio {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        profilePicture {
          childImageSharp {
            gatsbyImageData(width: 125, height: 125, layout: FIXED)
          }
        }
      }
    }
  }
`;
